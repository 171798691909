import { DeliveryType, InfraProvisioningStatus, Language } from '@teleport/schemas-protobuf';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardAccentColor } from 'src/blocks/wizard/WizardAccentColor/WizardAccentColor';
import { WizardEnd } from 'src/blocks/wizard/WizardEnd/WizardEnd';
import { BASKET, WizardHasBasket } from 'src/blocks/wizard/WizardHasBasket/WizardHasBasket';
import { WizardDeliveryType } from 'src/blocks/wizard/WizardOrderMethod/WizardOrderMethod';
import { WizardProductType } from 'src/blocks/wizard/WizardProductType/WizardProductType';
import { WizardShopName } from 'src/blocks/wizard/WizardShopName/WizardShopName';
import { WizardStart } from 'src/blocks/wizard/WizardStart/WizardStart';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import ModalStoreCreatedResult from 'src/components/ModalStoreCreatedResult/ModalStoreCreatedResult';
import StepsIndicator from 'src/components/StepsIndicator/StepsIndicator';
import StoreCreationScreen from 'src/components/StoreCreationScreen/StoreCreationScreen';
import { Button } from 'src/components/UI/Button/Button';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useBackButton from 'src/hooks/useBackButton';
import useLoader from 'src/hooks/useLoader';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useModalState } from 'src/hooks/useModalState';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { configState } from 'src/redux/slices/configSlice';
import {
  getWizardState,
  setAccentColor,
  setAgree,
  setDeliveryType,
  setHasBasket,
  setLanguage,
  setPromocode, setShopDescription,
  setShopLogoUrl,
  setShopName,
  setStoreFocus,
} from 'src/redux/slices/wizardSlice';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { StoreFocus, WIZARD_STEPS, WizardSteps } from 'src/types/wizard';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { multipleStoreState, setActiveStore } from '../../redux/slices/multipleStoreSlice';

const ORDER_OF_STEPS = [
  WIZARD_STEPS.NAME_AND_LOGO,
  WIZARD_STEPS.COLOR,
  WIZARD_STEPS.PRODUCT_TYPE,
  WIZARD_STEPS.ORDER_METHOD,
  WIZARD_STEPS.HAS_BASKET,
];

export const WizardPage = memo(function WizardPage() {
  const BackButton = useBackButton();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { config } = useAppSelector(configState);
  const [previousActiveStoreUuid] = useLocalStorage('previousActiveStoreUuid');
  const [isCreatingAnotherStore, setIsCreatingAnotherStore] = useLocalStorage('isCreatingAnotherStore');
  const { setActiveStoreNetworkStatus } = useAppSelector(multipleStoreState);
  const {
    wizard: wizardData,
    complete: wizardIsComplete,
    interruptedStep,
    infraProvisioningStatus,
    networkStatus,
    saveNetworkStatus,
  } = useAppSelector(getWizardState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [seenCongratulationsStoreModal] = useLocalStorage('seenCongratulationsStoreModal');
  const [renderResultModal, activeResultModal, openResultModal, closeResultModal] = useModalState();
  const [currentStep, setCurrentStep] = useState<WizardSteps>(() => {
    if (interruptedStep) {
      return interruptedStep;
    }
    if (wizardIsComplete) {
      return 'end';
    }
    return 'start';
  });
  const [logo, setLogo] = useState<{ shopLogo: null | File; shopLogoUrl: string }>({
    shopLogo: null,
    shopLogoUrl: wizardData.shopLogoUrl,
  });

  const intervalID = useRef(null);
  const [startCreatingStore, setStartCreatingStore] = useState(false);
  const [storeReadinessStatus, setStoreReadinessStatus] = useState<
    'inProgress' | 'sucess' | 'error' | 'dontStartCreating'
  >('dontStartCreating');

  const pingWizardSaveState = useCallback(() => {
    if (networkStatus === NetworkStatus.Loading) return;
    dispatch(wizardGetState());
  }, [dispatch, networkStatus]);

  useEffect(() => {
    if (wizardIsComplete) {
      setCurrentStep('end');
    }
  }, [wizardIsComplete]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState());
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, dispatch]);

  // start ping request
  useEffect(() => {
    if (startCreatingStore && wizardIsComplete && !intervalID.current) {
      setStoreReadinessStatus('inProgress');
      intervalID.current = setInterval(() => {
        pingWizardSaveState();
      }, 3000);
    }
  }, [pingWizardSaveState, startCreatingStore, wizardIsComplete]);

  // listener changes infraProvisioningStatus
  useEffect(() => {
    if (!wizardIsComplete) return undefined;
    switch (infraProvisioningStatus) {
      case InfraProvisioningStatus.UNSPECIFIED:
        return undefined;
      case InfraProvisioningStatus.CREATE_IN_PROGRESS:
        setStartCreatingStore(true);
        setStoreReadinessStatus('inProgress');
        BackButton?.hide();
        break;
      case InfraProvisioningStatus.CREATE_SUCCESS:
        setCurrentStep('end');
        setStoreReadinessStatus('sucess');
        setStartCreatingStore(false);
        clearInterval(intervalID.current);
        intervalID.current = null;
        BackButton?.hide();
        if (seenCongratulationsStoreModal) {
          navigate(RoutePath.Main);
        } else {
          openResultModal();
        }
        break;
      case InfraProvisioningStatus.CREATE_ERROR:
        setCurrentStep('end');
        setStoreReadinessStatus('error');
        setStartCreatingStore(false);
        clearInterval(intervalID.current);
        intervalID.current = null;
        BackButton?.hide();
        openResultModal();
        break;
      default:
        setCurrentStep('end');
        setStoreReadinessStatus('sucess');
        setStartCreatingStore(false);
        clearInterval(intervalID.current);
        intervalID.current = null;
        BackButton?.hide();
        if (seenCongratulationsStoreModal) {
          navigate(RoutePath.Main);
        } else {
          openResultModal();
        }
    }
  }, [
    infraProvisioningStatus,
    seenCongratulationsStoreModal,
    BackButton,
    startCreatingStore,
    wizardIsComplete,
    openResultModal,
    navigate,
  ]);

  useEffect(() => {
    if (interruptedStep) {
      setCurrentStep(interruptedStep);
    }
  }, [interruptedStep]);

  const prevStep = useCallback(() => {
    let prevIndex;

    if (currentStep === 'start') {
      return;
    } else if (currentStep === 'end') {
      prevIndex = ORDER_OF_STEPS.length - 1;
    } else {
      const index = ORDER_OF_STEPS.indexOf(currentStep);
      prevIndex = index - 1;
    }

    if (prevIndex < 0) {
      setCurrentStep('start');
      return;
    }

    setCurrentStep(ORDER_OF_STEPS[prevIndex]);
  }, [currentStep]);

  useEffect(() => {

    function goBack() {
      prevStep();
      if (currentStep === 'start') {
        BackButton?.hide();
      }
    }

    function goBackWhenCreatingAnotherStore() {
      dispatch(setActiveStore({storeUuid: previousActiveStoreUuid}))
    }

    if (currentStep === 'start' && isCreatingAnotherStore) {
      BackButton.show();
      BackButton.onClick(goBackWhenCreatingAnotherStore);
    }
    else if (currentStep === 'start') {
      BackButton?.hide();
    }
    else {
      BackButton.show();
      BackButton.onClick(goBack);
    }

    return () => {
      BackButton?.offClick(goBack);
      BackButton?.offClick(goBackWhenCreatingAnotherStore);
    };
  }, [
    previousActiveStoreUuid,
    setIsCreatingAnotherStore,
    dispatch,
    BackButton,
    isCreatingAnotherStore,
    currentStep,
    prevStep]);

  useEffect(() => {
    if(setActiveStoreNetworkStatus === NetworkStatus.Done) {
      setIsCreatingAnotherStore(false);
      navigate(RoutePath.Main);
      dispatch(wizardGetState());
    }
  }, [
    navigate,
    dispatch,
    setIsCreatingAnotherStore,
    setActiveStoreNetworkStatus
  ]);

  const disabledNextButton = () => {
    switch (currentStep) {
      case WIZARD_STEPS.NAME_AND_LOGO:
        return !Boolean(wizardData.shopName) || !Boolean(wizardData.shopLogoUrl) || !Boolean(wizardData.shopDescription);
      case WIZARD_STEPS.COLOR:
        return false;
      case WIZARD_STEPS.PRODUCT_TYPE:
        return !Boolean(wizardData.storeFocus.case);
      case WIZARD_STEPS.ORDER_METHOD:
        return !Boolean(wizardData.deliveryType);
      case WIZARD_STEPS.HAS_BASKET:
        return !Boolean(wizardData.hasBasket);
      case 'end':
        return !wizardData.agree || !Boolean(wizardData.supportLink) || !wizardData.language || !wizardData.currency;
      default:
        return false;
    }
  };

  const changePromocode = (code: string) => {
    dispatch(setPromocode(code));
  };

  const changeShopName = (name: string) => {
    dispatch(setShopName(name));
  };

  const changeShopDescription = (value: string) => {
    dispatch(setShopDescription(value));
  };

  const changeShopLogo = (logo: File) => {
    const url = URL.createObjectURL(logo);
    dispatch(setShopLogoUrl(url));
    setLogo({ shopLogo: logo, shopLogoUrl: url });
  };

  const changeAccentColor = (color: string) => {
    dispatch(setAccentColor(color));
  };

  const changeStoreFocus = (type: StoreFocus) => {
    dispatch(setStoreFocus(type));
  };

  const changeDeliveryType = (type: DeliveryType) => {
    dispatch(setDeliveryType(type));
  };

  const changeHasBasket = (value: BASKET | null) => {
    dispatch(setHasBasket(value));
  };

  const changeLanguage = (value: Language) => {
    dispatch(setLanguage(value));
  };

  const changeAgree = (value: boolean) => {
    dispatch(setAgree(value));
  };

  const nextStep = useCallback(() => {
    if (saveNetworkStatus === NetworkStatus.Loading) return;

    let nextIndex;

    if (currentStep === 'end') {
      return;
    } else if (currentStep === 'start') {
      nextIndex = 0;
    } else {
      const index = ORDER_OF_STEPS.indexOf(currentStep);
      nextIndex = index + 1;
    }

    if (nextIndex > ORDER_OF_STEPS.length - 1) {
      setCurrentStep('end');
      return;
    }

    setCurrentStep(ORDER_OF_STEPS[nextIndex]);
  }, [saveNetworkStatus, currentStep]);

  const saveWizardStepData = useCallback(() => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(wizardData, false);
    dispatch(wizardSaveState({ requestData: wizardStateRequest, shopLogoFile: logo.shopLogo }))
      .unwrap()
      .then(() => {
        setLogo(logo => ({ ...logo, shopLogo: null }));
        nextStep();
      });
  }, [logo, wizardData, nextStep, dispatch]);

  const completeTheWizard = () => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(wizardData, true);
    dispatch(wizardSaveState({ requestData: wizardStateRequest, shopLogoFile: logo.shopLogo }))
      .unwrap()
      .then(() => {
        if (currentStep === 'end') {
          setStartCreatingStore(true);
        }
        window.scrollTo(0, 0);
        BackButton?.hide();
      })
      .catch(e => console.error(e));
  };

  let content;
  if (currentStep === 'start') {
    content = <WizardStart changePromocode={changePromocode} promocode={wizardData.promocode} />;
  } else if (currentStep === WIZARD_STEPS.NAME_AND_LOGO) {
    content = (
      <WizardShopName
        shopName={wizardData.shopName}
        shopDescription={wizardData.shopDescription}
        logoUrl={logo.shopLogoUrl}
        setShopName={changeShopName}
        setShopDescription={changeShopDescription}
        setShopLogo={changeShopLogo}
      />
    );
  } else if (currentStep === WIZARD_STEPS.COLOR) {
    content = (
      <WizardAccentColor
        setAccentColor={changeAccentColor}
        accentColor={wizardData.accentColor || config.accentColor}
      />
    );
  } else if (currentStep === WIZARD_STEPS.PRODUCT_TYPE) {
    content = (
      <WizardProductType
        setStoreFocus={changeStoreFocus}
        selectedValue={wizardData.storeFocus.value}
      />
    );
  } else if (currentStep === WIZARD_STEPS.ORDER_METHOD) {
    content = (
      <WizardDeliveryType
        setDeliveryTypeMethod={changeDeliveryType}
        deliveryType={wizardData.deliveryType}
      />
    );
  } else if (currentStep === WIZARD_STEPS.HAS_BASKET) {
    content = (
      <WizardHasBasket
        deliveryType={wizardData.deliveryType}
        hasBasket={wizardData.hasBasket}
        setHasBasket={changeHasBasket}
      />
    );
  } else if (currentStep === 'end') {
    content = (
      <WizardEnd
        setLanguage={changeLanguage}
        setAgree={changeAgree}
        language={wizardData.language}
        agree={wizardData.agree}
        currency={wizardData.currency}
      />
    );
  }

  return (
    <Layout
      footer={
        <Footer>
          <Button
            text={
              currentStep === 'start'
                ? t('wizardPage.start')
                : currentStep === WIZARD_STEPS.HAS_BASKET
                  ? t('wizardPage.createStore')
                  : currentStep === 'end'
                    ? t('wizardPage.start')
                    : t('wizardPage.continue')
            }
            propsStyles={{
              width: '100%',
            }}
            disabled={saveNetworkStatus === NetworkStatus.Loading ? true : disabledNextButton()}
            onClick={currentStep === 'end' ? completeTheWizard : saveWizardStepData}
          />
        </Footer>
      }
    >
      {storeReadinessStatus === 'inProgress' && <StoreCreationScreen />}
      {currentStep !== 'start' && currentStep !== 'end' && (
        <StepsIndicator
          amount={ORDER_OF_STEPS.length}
          activeIndex={ORDER_OF_STEPS.indexOf(currentStep)}
        />
      )}

      {/* <button style={{ position: 'fixed', top: '30px', left: '30px' }} onClick={prevStep}>
        Назад
      </button> */}
      {content}
      {renderResultModal && (
        <ModalStoreCreatedResult
          active={activeResultModal}
          onClose={closeResultModal}
          isSuccess={storeReadinessStatus === 'sucess'}
        />
      )}
    </Layout>
  );
});
