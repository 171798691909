export enum Toggles {
  subscriptionEnabled = 'subscriptionEnabled',
  cartEnabled = 'cartEnabled',
  multipleStoreEnabled = 'multipleStoreEnabled'
}

export interface IRemoteConfig {
  router?: {
    'hub-api': string;
    'fs-api': string;
    'billing-api': string;
  };
  accentColor?: string;
  hubBotUsername?: string;
  portBotUsername?: string;
  toggles?: Record<Toggles, boolean>;
}

export interface IConfig {
  hubApiUrl?: string;
  fsApiUrl?: string;
  billingApiUrl?: string;
  accentColor?: string;
  hubBotUsername?: string;
  portBotUsername?: string;
  toggles?: Record<Toggles, boolean>;
}
