import { FC, memo, useEffect } from 'react';
import { ReactComponent as SvgShop } from 'src/assets/svg/shop.svg';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import Footer from '../Footer/Footer';
import { storeNoteWorkingRules } from './storeNotWorking.style';
import useLoader from 'src/hooks/useLoader';
import { useTelegram } from 'src/utils/telegramProvider';
import { getTelegramLanguage } from 'src/utils/i18n/helpers/getTelegramLanguage';

interface IProps {
  reasonText: string;
  supportLink?: string;
}

const StoreNotWorking: FC<IProps> = memo(function StoreNotWorking(props) {
  const { css } = useStyle(storeNoteWorkingRules);
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const { reasonText } = props;
  const Telegram = useTelegram();

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const language = getTelegramLanguage()
  
  return (
    <div className={css.app}>
      <div>
        <div className={css.wrapper}>
          <SvgShop className={css.img} />
          <span className={[css.text, css.title].join(' ')}>{language === 'en'? 'The hub is temporarily out of service' : 'Хаб временно не работает'}</span>
          <span className={[css.text, css.subtitle].join(' ')}>{reasonText}</span>
        </div>

        <Footer propsStyles={{ padding: '8px 16px', backgroundColor: theme.background }}>
          <button className={css.button} onClick={connectToSupport} style={{
            width: '100%',
            height: 62,
            background: theme.colorBlack,
          }} > {language === 'en'? 'Contact support' : 'Связаться с поддержкой'}</button>
        </Footer>
      </div>
    </div>
  );
});

export default StoreNotWorking;
