import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalUniversalKeys {
  modalInner: {};
  header: {};
  info: {};
  footer: {};
  wrapper: {};
  subtitle: {};
}

interface IModalUniversalProps {
  headerHeight: number;
  footerHeight?: number;
}

export type IModalUniversal = IRuleFn<IModalUniversalKeys, IModalUniversalProps>;

const ModalUniversalRuleFn: IModalUniversal = props => {
  const { colorRgbBlack, mainColor, sidePadding, colorWhite } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
      paddingBottom: footerHeight ?? 46,
    },
    header: {
      ...padding(15, sidePadding, 15),
      marginBottom: 10,
    },
    info: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: `rgba(${colorRgbBlack}, .4)`,

      '& a': {
        color: mainColor,
      },
    },
    wrapper: {
      ...padding(12, 0),
    },
    footer: {
      width: `calc(100% - ${2 * sidePadding}px)`,
      position: 'fixed',
      backgroundColor: colorWhite,
      bottom: 0,
      paddingTop: 12,
      paddingBottom: 30,
    },
    subtitle: {
      fontSize: 16,
      fontWeight: '500',
      lineHeight: '22px',
      letterSpacing: '-0.4px',
      marginBottom: '40px',
      color: colorRgbBlack,
    },
  };
};

export const modalModifierTypeRules = createCachedStyle(ModalUniversalRuleFn);
