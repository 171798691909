import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { MainPageHeaderRules } from './MainPageHeader.style';
import Text from 'src/components/UI/Text/Text';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { subscriptionState } from 'src/redux/slices/subscriptionSlice';
import { formatDate } from '../../utils/date/formatDate';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { useModalState } from 'src/hooks/useModalState';
import { StoreCard } from 'src/components/StoreCard/StoreCard';
import { configState, togglesState } from 'src/redux/slices/configSlice';
import {
  createNewStore,
  getStoreList,
  multipleStoreState, resetNetworkStatus,
  setActiveStore
} from '../../redux/slices/multipleStoreSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { wizardGetState } from '../../redux/api/wizard/wizardGetState';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ModalError from '../../components/ModalError/ModalError';
import useLoader from '../../hooks/useLoader';

export const MainPageHeader: FC = memo(function MainPageHeader(props) {
  const {} = props;
  const { css } = useStyle(MainPageHeaderRules);
  const { config } = useAppSelector(configState);
  const { subscriptionEnabled, multipleStoreEnabled } = useAppSelector(togglesState);
  const { wizard } = useAppSelector(getWizardState);
  const {finishedAt, subscriptionActive} = useAppSelector(subscriptionState)
  const { t } = useTranslation();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const dispatch = useAppDispatch();
  const {
    stores,
    createStoreNetworkStatus,
    setActiveStoreNetworkStatus,
    networkStatus,
  } = useAppSelector(multipleStoreState);
  const [, setPreviousActiveStoreUuid] = useLocalStorage('previousActiveStoreUuid');
  const [, setIsCreatingAnotherStore] = useLocalStorage('isCreatingAnotherStore');
  const { showLoader } = useLoader();

  useEffect(() => {
    if(createStoreNetworkStatus === NetworkStatus.Done) {
      dispatch(wizardGetState());
    }
    if(createStoreNetworkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if(createStoreNetworkStatus === NetworkStatus.Failed) {
      openErrorModal();
    }
  }, [dispatch, openErrorModal, showLoader, createStoreNetworkStatus]);

  useEffect(() => {
    if(setActiveStoreNetworkStatus === NetworkStatus.Done) {
      closeModal();
      dispatch(resetNetworkStatus('setActiveStoreNetworkStatus'));
      dispatch(wizardGetState());
    }
    if(setActiveStoreNetworkStatus === NetworkStatus.Failed) {
      openErrorModal();
    }
  }, [setActiveStoreNetworkStatus, dispatch, openErrorModal, closeModal]);

  useEffect(() => {
    if(networkStatus === NetworkStatus.Failed) {
      openErrorModal();
      closeModal();
    }
  }, [networkStatus, openErrorModal, closeModal]);

  const onClickCreateNewStore = () => {
    const activeStore = stores.find(el => el.active);
    if(!activeStore) return;
    setPreviousActiveStoreUuid(activeStore.store.uuid);
    setIsCreatingAnotherStore(true);
    dispatch(createNewStore());
  }

  const openSelectStoreModal = () => {
    if(!multipleStoreEnabled) return
    if(networkStatus === NetworkStatus.None || networkStatus === NetworkStatus.Failed) {
      dispatch(getStoreList());
    }
    openModal()
  }

  const onClickStoreCard = (storeUuid: string) => {
    dispatch(setActiveStore({ storeUuid }))
  }

  return (
    <>
      <div className={css.wrapper}>
        <div className={css.generalInfo} onClick={openSelectStoreModal}>
          <div className={css.shopLogoWrp}>
            <img className={css.shopLogo} src={wizard.shopLogoUrl} alt="logo"/>
          </div>
          <div>
            <Text
              mod="title"
              text={wizard.shopName}
              fontSize={14}
              fontWeight={600}
              lineHeight={16}
            />
            <Text
              mod="text"
              text={`@${config.portBotUsername}`}
              fontSize={12}
              fontWeight={500}
              lineHeight={16}
              extend={css.telegram}
            />
          </div>
        </div>
        {subscriptionEnabled && (
          <div className={css.subscription}>
            <Text
              mod="text"
              text={t('mainPageHeader.subscription')}
              fontSize={12}
              lineHeight={16}
              fontWeight={500}
              extend={css.subscriptionTitle}
            />
            <Text
              mod="text"
              text={subscriptionActive && finishedAt
                ? t('mainPageHeader.activeUntil', formatDate(finishedAt))
                : t('mainPageHeader.inactive')}
              fontSize={12}
              lineHeight={16}
              fontWeight={500}
              extend={!subscriptionActive ? css.redColor : ''}
            />
          </div>
        )}

        {multipleStoreEnabled && renderModal && (
          <ModalUniversal
            title={t('mainPageHeader.myStores')}
            active={activeModal}
            onClose={closeModal}
            footerBtn={
              {
                text: t('mainPageHeader.createNewStore'),
                callback: onClickCreateNewStore,
              }
            }
          >
            {networkStatus === NetworkStatus.Loading
              ? <div className={css.spinnerWrapper}>
                <Spinner/>
              </div>
              : <div className={css.cardGrid}>
                {stores.map(el =>
                  <StoreCard
                    key={el.store.uuid}
                    storeContext={el}
                    onClick={onClickStoreCard}
                  />
                )}
              </div>
            }
          </ModalUniversal>
        )}

      </div>
      {renderErrorModal && (
        <ModalError onClose={closeErrorModal} title={t('mainPageHeader.error')} active={activeErrorModal}/>
      )}
    </>
  );
});
